<template>
    <div :class="navigation != true?'height_100 py-4 py-md-7':'Inbox height_100 py-4 py-md-7'" id="menu-bar" :style="'--inboxWidth: '+ inboxWidth+'vw'">
        <!-- <div v-if="upchat"></div> -->
        <div :class="navigation != true?'top w-100':'top Inbox-mf'">
            <div class="d-flex justify-center align-center mb-4">
                <!-- <div class="logo pointer" @click="toHomePage()" ></div> -->
                <div v-if="menuMember.userid != ''" class="logo pointer" @click="openInboxNavigation()">
                    <v-badge overlap :value="unreadMessageCount" :content="unreadMessageCount" color="error">
                        <img src="../assets/images/Logo_svg.svg" />
                    </v-badge>
                </div>
                <div class="logo " v-else>
                    <img src="../assets/images/Logo_svg.svg" />
                </div>
            </div>
            <v-list class="menu-nav" :class="isToggle ? 'is-active' : ''" z-index='9999'>
                <div v-for="item in upMenuRouters" :key="item.name">
                    <v-list-item :class='$route.path == "/UpChat" && item.name == $t("UPCHAT.CIC") ? screen_ratio_class_CIC : screen_ratio_class' style="margin-bottom:6px !important;"> 
                        <v-tooltip right="right">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="nav-btn mx-auto" :to="item.path" @click="isToggle = false" :ripple="false" :draggable="false" v-bind="attrs" v-on="on" :icon="!isMobile" depressed>                                    
                                    <v-menu v-if="!checkLink(item) && item.name == $t('Bulk_Message.TITLE')" bottom :offset-x="offset" nudge-right="18">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on"  size="23">{{ item.normal }}</v-icon>
                                        </template>
                                        <v-list class="pa-0">
                                            <v-list-item class="text-center" @click='toSegments()' >{{$t('SEGMENT.TITLE')}}</v-list-item>
                                            <v-list-item class="text-center" @click='toCampaigns()'>{{$t('CAMPAIGN.TITLE')}}</v-list-item>
                                        </v-list>
                                    </v-menu>
                                    <v-menu v-else-if="checkLink(item) && item.name == $t('Bulk_Message.TITLE')" bottom :offset-x="offset" nudge-right="18">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on"  size="23">{{ item.active }}</v-icon>
                                        </template>
                                        <v-list class="pa-0">
                                            <v-list-item class="text-center" @click='toSegments()' >{{$t('SEGMENT.TITLE')}}</v-list-item>
                                            <v-list-item class="text-center" @click='toCampaigns()'>{{$t('CAMPAIGN.TITLE')}}</v-list-item>
                                        </v-list>
                                    </v-menu>
                                    <v-menu v-else-if="!checkLink(item) && item.name == $t('GENERAL.DASHBOARD')" bottom :offset-x="offset" nudge-right="18">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on"  size="23">{{ item.normal }}</v-icon>
                                        </template>
                                        <v-list class="pa-0">
                                            <v-list-item class="text-center" @click='toDealDashboard()'>{{$t('DASHBOARD.TITLE')}}</v-list-item>
                                            <v-list-item class="text-center" @click='toCaseDashboard()'>{{$t('CASEDASHBOARD.TITLE')}}</v-list-item>
                                            <v-list-item v-if="showUpchatDashboard" class="text-center" @click='toUpChatDashboard()'>{{$t('UPCHATDASHBOARD.TITLE')}}</v-list-item>
                                        </v-list>
                                    </v-menu>
                                    <v-menu v-else-if="checkLink(item) && item.name == $t('GENERAL.DASHBOARD')" bottom :offset-x="offset" nudge-right="18">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on"  size="23">{{ item.active }}</v-icon>
                                        </template>
                                        <v-list class="pa-0">
                                            <v-list-item class="text-center" @click='toDealDashboard()'>{{$t('DASHBOARD.TITLE')}}</v-list-item>
                                            <v-list-item class="text-center" @click='toCaseDashboard()'>{{$t('CASEDASHBOARD.TITLE')}}</v-list-item>
                                            <v-list-item v-if="showUpchatDashboard" class="text-center" @click='toUpChatDashboard()'>{{$t('UPCHATDASHBOARD.TITLE')}}</v-list-item>
                                        </v-list>
                                    </v-menu>
                                    <img v-else-if='item.name == $t("DEAL.TITLE")' width='30' :src="require($route.path == '/Deals' ? '@/assets/images/Opty_clicked.svg' : '@/assets/images/Opty.svg')">
                                    <img v-else-if='item.name =="UpChat"' width='30' src="../assets/images/UpChat.svg" @click='toUpChatHost()'>
                                    <img v-else-if='item.name == $t("UPCHAT.CIC")' width='30' :src="require($route.path == '/UpChat' ? '@/assets/images/UpDayCIC_clicked.svg' : '@/assets/images/UpDay CIC.svg')">
                                    <img v-else-if='item.name =="UpGPT"' width='30' src="../assets/images/menu_upgpt_star.png">
                                    <v-icon v-else-if="checkLink(item)" size="23">{{ item.active }}</v-icon>
                                    <v-icon v-else size="23">{{ item.normal }}</v-icon>
                                    <span v-if="isMobile">{{ item.name }}</span>
                                </v-btn>
                            </template>
                            <span>{{ item.name }}</span>
                        </v-tooltip>
                    </v-list-item>
                    <v-divider v-if='addDivider(item)' class="mx-4"></v-divider>
                </div>
            </v-list>
            <!-- <iframe id="upchat_vframe" class="vframe" title="UpChat" src=""></iframe> -->
        </div>
        <div v-if="hideAvatar"></div>
        <div v-else-if="menuMember.userid != ''" :class="navigation != true?'bottom d-flex flex-column align-center':'bottom d-flex flex-column align-center Inbox-mf'" >
            <v-btn class="hamburger" v-if="isMobile" @click="toggleMenu" :class="isToggle ? 'is-active' : ''" :ripple="false" :draggable="false" width="50" min-width="50" depressed>
                <span class="line"></span>
                <span class="line"></span>
                <span class="line"></span>
            </v-btn>
            <v-list>
                <v-list-item class="mb-2">
                    <v-tooltip right="right">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="nav-btn mx-auto" :to="downMenuRouter.path" @click="isToggle = false" :ripple="false" :draggable="false" v-bind="attrs" v-on="on" :icon="!isMobile" depressed>
                                <v-icon v-if="checkLink(downMenuRouter)" size="23">{{ downMenuRouter.active }}</v-icon>
                                <v-icon v-else size="23">{{ downMenuRouter.normal }}</v-icon>
                                <span v-if="isMobile">{{ downMenuRouter.name }}</span>
                            </v-btn>
                        </template>
                        <span>{{ downMenuRouter.name }}</span>
                    </v-tooltip>
                </v-list-item>
            </v-list>
            <v-menu top="top" :offset-x="offset" nudge-right="18">
                <template v-slot:activator="{ on, attrs }">
                    <v-badge bordered bottom :color='menuMember.status == "ONLINE" ? "green" : menuMember.status == "AWAY" ? "orange" : menuMember.status == "BUSY" ? "red" : "grey"' overlap dot offset-x="12" offset-y="12" :value='menuMember.is_service_agent'>
                        <v-avatar color="aliceBlue" v-bind="attrs" v-on="on">
                            <span v-if="menuMember.pic === ''">{{ menuMember.nickname }}</span>
                            <img v-else :src="member.pic" :alt="menuMember.name" />
                        </v-avatar>
                    </v-badge>
                </template>
                <v-list class="pa-0">
                    <div v-if='menuMember.is_service_agent'>
                        <v-list-item class="text-center">{{ $t('GENERAL.STATUS') }}</v-list-item>
                        <v-list-item class="text-center" @click="changeStatus('ONLINE')">
                            <v-icon class='mr-3' color='green' size='12'>fas fa-circle</v-icon>
                            <div class='t-black'>{{ $t('AGENTSTATUSLIST.ONLINE') }}</div>
                        </v-list-item>
                        <v-list-item class="text-center" @click="changeStatus('AWAY')">
                            <v-icon class='mr-3' color='orange' size='12'>fas fa-circle</v-icon>
                            <div class='t-black'>{{ $t('AGENTSTATUSLIST.AWAY') }}</div>
                        </v-list-item>
                        <v-list-item class="text-center" @click="changeStatus('BUSY')">
                            <v-icon class='mr-3' color='red' size='12'>fas fa-circle</v-icon>
                            <div class='t-black'>{{ $t('AGENTSTATUSLIST.BUSY') }}</div>
                        </v-list-item>
                        <v-list-item class="text-center" @click="changeStatus('OFFLINE')">
                            <v-icon class='mr-3' color='gary' size='12'>far fa-times-circle</v-icon>
                            <div class='t-black'>{{ $t('AGENTSTATUSLIST.OFFLINE') }}</div>
                        </v-list-item>
                        <v-divider class='ma-0'></v-divider>
                    </div>

                    <v-list-item class="text-center">{{ $t('GENERAL.SHORTCUTS') }}</v-list-item>
                    <v-list-item class="text-center" @click="toSetting()">
                        <v-icon class='mr-2'>mdi-account</v-icon>
                        <div class='t-black'>{{ $t('SETTING.PERSONALSETTING') }}</div>
                    </v-list-item>
                    <v-list-item class="text-center" @click="onlogout()">
                        <v-icon class='mr-2'>mdi-power</v-icon>
                        <div class='t-black'>{{$t('GENERAL.LOGOUT')}}</div>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
        <v-dialog v-model='messageDialog' width='360' content-class='statusDialog' v-if='messageDialog'>
              <message-dialog @emitMessageDialog='onEmitMessageDialog' :message='message'></message-dialog >
        </v-dialog>
        <v-navigation-drawer hide-overlay :temporary='navigation? true:false' id="inbox_navigation" v-if="menuMember.userid != ''" class="inbox" v-model="drawer" absolute  width="800"  style="box-shadow:5px 0 5px -5px;">
            <inbox ref='inbox' @updateInboxUnreadCount="updateInboxUnreadCount" @closeInboxNavigation="closeInboxNavigation"></inbox>
        </v-navigation-drawer>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import PersonalSettingService from "@/services/PersonalSettingService";
    import passData from "@/services/passData.js";
    import i18n from '/common/plugins/vue-i18n.js';
    import messageDialog from '@/components/Dialog/redirectDialog';
    import inbox from '@/components/Inbox/Inbox.vue';
    import AccountDataService from '../services/AccountDataService';
    import PreferenceService from "@/services/PreferenceService";
    import UpChatDataService from "@/services/UpChatDataService";
    import { getMessaging, onMessage, getToken } from "firebase/messaging";

    export default {
        name: 'MenuBar',
        props: {
            member: { 
                type: Object 
            }
        },
        components: {
            messageDialog,
            inbox
        },
        data() {
            return {
                upMenuRouters: [
                    // {
                    // 	path: '/',
                    // 	name: 'Home',
                    // 	normal: 'icon-home-border',
                    // 	active: 'icon-home'
                    // },
                    {
                        name: i18n.t('GENERAL.DASHBOARD'),
                        normal: 'icon-dashboard-border',
                        active: 'icon-dashboard',
                    },
                    {
                        path: '/Activity',
                        name: i18n.t('GENERAL.ACTIVITY'),
                        normal: 'icon-calendar',
                        active: 'icon-calendar',
                    },
                    {
                        path: '/Contacts',
                        name: i18n.t('CONTACT.TITLE'),
                        normal: 'icon-contact-border',
                        active: 'icon-contact',
                    },
                    {
                        path: '/Companies',
                        name: i18n.t('COMPANY.TITLE'),
                        normal: 'icon-companies-w',
                        active: 'icon-companies-b',
                    },
                    {
                        path: '/Deals',
                        name: i18n.t('DEAL.TITLE'),
                        normal: 'icon-chatmoney-border',
                        active: 'icon-chatmoney',
                    },
                    {
                        path: '/Cases',
                        name: i18n.t('CASE.TITLE'),
                        normal: 'mdi-wrench-outline',
                        active: 'mdi-wrench',
                    },
                    {
                        path: '/KnowledgeArticles',
                        name: i18n.t('KNOWLEDGE_ARTICLE.TITLE'),
                        normal: 'mdi-book-open-variant',
                        active: 'mdi-book-open-page-variant',
                    },
                    {
                        name: i18n.t('Bulk_Message.TITLE'),
                        normal: 'mdi-email-multiple-outline',
                        active: 'mdi-email-multiple',
                    }
                ],
                downMenuRouter:
                {
                    path: '/Setting',
                    name: i18n.t('SETTING.TITLE'),
                    normal: 'icon-setting-w',
                    active: 'icon-setting-b',
                },
                unreadMessageCount:0,
                navigation:false,
                drawer:true,
                offset: true,
                isMobile: false,
                isToggle: false,	
                newPic: null,
                userPic: false,
                newName: null,
                newAgentData: null,
                menuMember:{userid:''},
                memberData: null,
                statusColor: 'grey',
                messageDialog: false,
                message: '',
                upchat: this.$route.meta.upchat,
                hideAvatar: false,
                isUpchatUser: window.localStorage.getItem('isUpchatUser'),
                showUpchatDashboard: false,
                hasUpchatLogin: false,
                screen_ratio_class_CIC:"",
                screen_ratio_class:"",
                // showInbox:true,
                fcmToken: window.localStorage.getItem('fcm_token'),
                inboxWidth:0,
            };
        },
        watch: {
            "member":async function () {
                if(this.member.userid != '')
                {
                    this.menuMember = this.member;
                    await this.getUserPhoto();
                }
            },
            "newPic": function () {
                this.menuMember.pic = this.newPic
            },
            "newName": function () {
                this.menuMember.name = this.newName.last_name+' '+this.newName.first_name;				
            },
            "newAgentData": function () {
                this.menuMember.is_service_agent = this.newAgentData.is_service_agent;
                this.menuMember.status = this.newAgentData.status;
            },
            "drawer": function (){
                if(!this.drawer)
                {
                    this.closeInboxNavigation();
                    setTimeout(()=>{
                        this.drawer = true;
                    },10)
                }
            }
        },
        created() {
            if (window.localStorage.getItem('upchat_login') != null)
                this.hasUpchatLogin = window.localStorage.getItem('upchat_login') === 'true' ? true : false;
            this.menuMember = this.member;
            let access_token = window.localStorage.getItem('access_token');
            if(access_token != null){
                this.getUserDetail();
                this.initWebPush();
            }
            if(this.isUpchatUser == 'true'){
                this.add_upchat_icon();
            }
            window.addEventListener("message",(msg)=>{this.hide(msg.data)}, false);
            window.addEventListener('resize', () => {this.return_screen_class()});
            PreferenceService.getUPGPT().then(response => 
            {
                let data = response.data
                if (data == "Y"){
                    this.add_upgpt_icon();
                }
            });
            // this.return_screen_class();
        },
        mounted() {
            this.onResize();	
            passData.$on("menuBarPic", (e) => {
                this.newPic = e
            })
            passData.$on("menuBarName", (e) => {
                this.newName = e
            })
            passData.$on("menuBarAgentData", (e) => {
                this.newAgentData = e;
            })
            passData.$on("status", () => {
                if(this.menuMember.is_service_agent){
                    this.changeStatus('ONLINE');
                }
            })
            passData.$on("upchat", (e) => {
                if(e == 'true'){
                    this.add_upchat_icon();
                }
            })
            document.getElementById('inbox_navigation').style.marginLeft = '-100px';
            this.return_screen_class();
        },
        methods: {
            initWebPush() {
                // console.log(this.$messaging);
                const messaging = getMessaging();
                //this.$messaging.onMessage(function (payload) {
                onMessage(messaging, (payload) => {
                    console.log('Message received. ', payload);
                    // let notification = payload.notification;
                    let notificationData = payload.data;
                    const notificationOptions = {
                        body: notificationData.body,
                        icon: notificationData.image,
                        requireInteraction: true,
                        data: { 
                            url: notificationData.url
                        }
                    };
                    // const notificationOptions = {
                    //     body: notificationData.body,
                    //     icon: notificationData.image,
                    //     requireInteraction: true,
                    //     data: { 
                    //         url: notificationData.url
                    //     },
                    //     actions: [{action: "get", title: "Get Now"}, {action: "done", title: "Done Now"}]
                    // };
                    navigator.serviceWorker.getRegistrations()
                    .then((registration) => {
                        if(notificationData.title != 'INBOX_DELETE')
                            registration[0].showNotification(notificationData.title, notificationOptions);
                    }).finally(()=>{
                        this.$refs.inbox.refresh();
                    });
                });

                //this.$messaging.getToken({ vapidKey: "BO_TiDP7-49OyifEI7gGzftPHtjsy7uHAOpOw4K57QvqVCu5kGXBPE7i9X66MqyhtGDDh4zr-wRX4dOI3lRJx0E" })
                getToken(messaging, { vapidKey: "BO_TiDP7-49OyifEI7gGzftPHtjsy7uHAOpOw4K57QvqVCu5kGXBPE7i9X66MqyhtGDDh4zr-wRX4dOI3lRJx0E" })
                .then((currentToken) => {
                    if (currentToken) {
                        console.log('client token', currentToken);
                    } else {
                        console.log('No registration token available. Request permission to generate one.');
                    }
                }).catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                });

                Notification.requestPermission().then((permission) => {
                    if (permission === 'granted') {
                        console.log('Notification permission granted.')
                        getToken(messaging, { vapidKey: "BO_TiDP7-49OyifEI7gGzftPHtjsy7uHAOpOw4K57QvqVCu5kGXBPE7i9X66MqyhtGDDh4zr-wRX4dOI3lRJx0E" }).then((token) => {          
                            console.log('New token created: ', token)
                            if (this.fcmToken == token) {
                                console.log('Same token');
                            } else {
                                console.log('Save notification token');
                                AccountDataService.saveNotificationToken(token);
                                window.localStorage.setItem('fcm_token', token);
                            }
                        })
                    }
                }).catch((err) => {
                    console.log('Unable to get permission to notify.', err)
                });
            },
            updateInboxUnreadCount(val){
                (!val|| val == "")?this.unreadMessageCount = 0:(val>99)?this.unreadMessageCount ="99+":this.unreadMessageCount = val;
            },
            openInboxNavigation(){
                if(this.menuMember.userid == ''){
                    return;
                }
                this.navigation=true;
                this.inboxWidth=45;
                document.getElementById('inbox_navigation').style.marginLeft = '80px';
            },
            closeInboxNavigation(){
                this.navigation=false;
                this.inboxWidth=0;
                document.getElementById('inbox_navigation').style.marginLeft = '-100px';
            },
            return_screen_class(){
                let screenHeight = screen.height;
                let browserRatio = window.innerHeight/window.outerHeight;
                let deviceRatio = 1/window.devicePixelRatio;
                let finalRatio = deviceRatio*browserRatio;
                if(screenHeight <= 768){
                    if(finalRatio <= 0.86 || (finalRatio>1 && finalRatio<=2)){
                        this.screen_ratio_class_CIC = "bg-CIC mb-2 v-list-item-small";
                        this.screen_ratio_class = "mb-2 v-list-item-small" ;
                        document.getElementById('menu-bar').style.justifyContent = '';
                    }else{
                        this.screen_ratio_class_CIC = "bg-CIC mb-2";
                        this.screen_ratio_class = "mb-2" ;
                        document.getElementById('menu-bar').style.justifyContent = 'space-between';
                    }
                }
                else if(screenHeight > 768 && screenHeight < 1024){
                    if(finalRatio <= 0.88 || (finalRatio>1 && finalRatio<=2)){
                        this.screen_ratio_class_CIC = "bg-CIC mb-2 v-list-item-small";
                        this.screen_ratio_class = "mb-2 v-list-item-small" ;
                        document.getElementById('menu-bar').style.justifyContent = '';
                    }else{
                        this.screen_ratio_class_CIC = "bg-CIC mb-2";
                        this.screen_ratio_class = "mb-2" ;
                        document.getElementById('menu-bar').style.justifyContent = 'space-between';
                    }
                }
                else{
                    if(finalRatio <= 0.8){
                        this.screen_ratio_class_CIC = "bg-CIC mb-2 v-list-item-small";
                        this.screen_ratio_class = "mb-2 v-list-item-small" ;
                        document.getElementById('menu-bar').style.justifyContent = '';
                    }else{
                        this.screen_ratio_class_CIC = "bg-CIC mb-2";
                        this.screen_ratio_class = "mb-2" ;
                        document.getElementById('menu-bar').style.justifyContent = 'space-between';
                    }
                } 
            },
            add_upchat_icon(){
                this.showUpchatDashboard = true;
                let upchat_paths = [
                    {
                        name: 'UpChat',
                        normal: 'mdi-chat-processing-outline',
                        active: 'mdi-chat-processing',
                    },
                    {
                        path: '/UpChat',
                        name: i18n.t('UPCHAT.CIC'),
                        normal: 'fa-regular fa-comments',
                        active: 'fa-solid fa-comments',
                    },
                ]
                let show_icon = this.upMenuRouters.find(el=>el.name == "UpChat");
                if(show_icon == undefined){
                    upchat_paths.forEach(e => {
                        this.upMenuRouters.push(e)
                    })
                }
            },
            remove_upchat_icon(){
                this.upMenuRouters = this.upMenuRouters.filter(el =>el.name != "UpChat" && el.name != i18n.t('UPCHAT.CIC'));
            },
            add_upgpt_icon(){
                let upgpt_paths = [
                    {
                        path: '/upgpt',
                        name: 'UpGPT',
                        normal: 'mdi-shimmer',
                    }
                ]
                let show_icon = this.upMenuRouters.find(el=>el.name == "UpGPT");
                if(show_icon == undefined){
                    upgpt_paths.forEach(e => {
                        this.upMenuRouters.push(e)
                    })
                }
            },
            remove_upgpt_icon(){
                this.upMenuRouters = this.upMenuRouters.filter(el =>el.name != "UpGPT");
            },
            hide(msg){
                if(msg == 'hide Avatar'){
                    this.hideAvatar = true;
                }
            },
            // async beforeUnloadHandler(e, newStatus){
            //     // 
            //     await this.changeUserStatus(newStatus);
            //     // call API to update
            //     navigator.sendBeacon(`http://127.0.0.1:8000//tenant/demo_2/api/personalsetting/${this.memberData[0].id}/status/`, this.memberData[0])
            //     // await PersonalSettingService.updateStatus(this.memberData[0].id, this.memberData[0]);
            // },
            // async changeUserStatus(newStatus){
            //     // set memberData data to new status
            //     this.memberData[0].usersetting.status = newStatus;

            //     // set menuMember data to new status
            //     this.menuMember.status = newStatus;
                
            //     // set local storage data to new status
            //     let userJson = window.localStorage.getItem('user');
            //     let user = JSON.parse(userJson);
            //     user.status = newStatus;
            //     window.localStorage.setItem('user', JSON.stringify(user));
            // },
            async getUserDetail(){
                await PersonalSettingService.getCurrentUserDetail()
                .then(response => {
                    this.memberData = response.data;
                })
            },
            setLang(){
                let lang = window.localStorage.getItem('language');
                if(lang != 'zh_TW')
                {
                    window.location.reload();
                }
            },
            getUserPhoto(){
                if(this.userPic === false){
                    PersonalSettingService.getCurrentUserDetail()
                    .then(response => 
                    {
                        if(response.data[0].usersetting.photo != null)
                        {
                            this.menuMember.pic = response.data[0].usersetting.photo						
                            const USER = 'user';
                            let userJson = window.localStorage.getItem(USER);
                            let user = JSON.parse(userJson);
                            user.pic = response.data[0].usersetting.photo                                               
                            window.localStorage.setItem(USER, JSON.stringify(user));	
                            this.userPic = true
                        }
                        else{
                            this.menuMember.pic = '';
                        }
                        this.setLang()
                    })
                }
            },
            checkLink: function (item) {
                const windowsHref = this.$route.path;
                if(item.name == i18n.t('Bulk_Message.TITLE')){
                    if (windowsHref == '/Segments' || windowsHref == '/Campaigns'){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                else if (item.name == i18n.t('GENERAL.DASHBOARD')) {
                    if (windowsHref == '/DealDashboard' || windowsHref == '/CaseDashboard'){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                else{
                    if (item.path === windowsHref)
                        return true;
                    else
                        return false;
                }
            },
            addDivider(item){
                if(item.name == i18n.t('Bulk_Message.TITLE')){
                    return true;
                }
                else if (item.name == i18n.t('COMPANY.TITLE')) {
                    return true;
                }
                else if (item.name == i18n.t('GENERAL.ACTIVITY')) {
                    return true;
                }
                else{
                    return false;
                }
            },
            toSegments(){
                if(this.$route.path !== '/Segments') this.$router.push('/Segments');
            },
            toCampaigns(){
                if(this.$route.path !== '/Campaigns') this.$router.push('/Campaigns');
            },
            toDealDashboard(){
                if(this.$route.path !== '/DealDashboard') this.$router.push('/DealDashboard');
            },
            toCaseDashboard(){
                if(this.$route.path !== '/CaseDashboard') this.$router.push('/CaseDashboard');
            },
            toUpChatDashboard(){
                let userJson = JSON.parse(window.localStorage.getItem('user'));
                if (userJson.upchat_dashboard != '') {
                    // if not login, put sso link to iframe src first
                    if (userJson.upchat_sso_link != '') {
                        window.open(userJson.upchat_dashboard, '_blank');
                    }
                }
            },
            async toUpChatHost(){
                let userJson = JSON.parse(window.localStorage.getItem('user'));
                // let upchat_login = JSON.parse(window.localStorage.getItem('upchat_login'));
                // if (userJson.upchat_host != '') {
                //     if (userJson.upchat_sso_link != '') {
                //         if(upchat_login){
                //            window.open(userJson.upchat_host, '_blank'); 
                //         }else{
                //             window.open(userJson.upchat_sso_link, '_blank');
                //             window.localStorage.setItem('upchat_login', true);
                //         }
                //     }
                // }
                if (userJson.upchat_host != '') {
                    if (userJson.upchat_sso_link != '') {
                        await UpChatDataService.singlesignon().then((response)=>{
                            let upchat_sso_link = response.data;
                            window.open(upchat_sso_link, '_blank');
                        })
                    }
                }
            },
            toSetting(){
                this.$router.replace('/Setting#PersonalSetting')
            },
            toHomePage(){
                if(this.$route.path !== '/') this.$router.push('/');
            },
            async onlogout() {
                await AccountDataService.saveNotificationToken('Logout');
                if(this.menuMember.is_service_agent){
                    this.memberData[0].usersetting.status = 'OFFLINE';
                    this.memberData[0].usersetting.default_value = JSON.parse(window.localStorage.getItem('user')).default_value
                    await PersonalSettingService.updateDetail(this.memberData[0].id, this.memberData[0])
                }
                let tenant = window.localStorage.getItem('tenant');
                

                await this.logout()
                .then(() => {
                    let redirectTo = (tenant != null || tenant != '') ? `/login?tenant=${tenant}` : '/login';
                    this.menuMember = { userid: ''};
                    this.userPic = false;
                    this.$emit('logoutSuccess');
                    this.$router.push(redirectTo);
                    this.showUpchatDashboard = false;
                    this.remove_upchat_icon();
                    this.remove_upgpt_icon();
                    this.closeInboxNavigation();
                })
            },
            onResize: function () {
                if (window.innerWidth < 767) {
                    this.isMobile = true;
                }
            },
            toggleMenu() {
                if (this.isToggle == false) {
                    this.isToggle = true;
                } else {
                    this.isToggle = false;
                }
            },
            ...mapActions([
                'logout',
            ]),
            async changeStatus(newStatus){
                await this.getUserDetail();

                if(this.memberData != null){
                    this.memberData[0].usersetting.status = newStatus;

                    PersonalSettingService.updateDetail(this.memberData[0].id, this.memberData[0])
                    .then(response => {
                        // set menuMember data to new status
                        this.menuMember.status = response.data.usersetting.status;

                        // set local storage data to new status
                        let userJson = window.localStorage.getItem('user');
                        let user = JSON.parse(userJson);
                        user.status = response.data.usersetting.status;
                        window.localStorage.setItem('user', JSON.stringify(user));
                    })
                    .catch(response => {
                        console.log(response);
                    })
                }
            },
            onEmitMessageDialog(){
                this.messageDialog = false;
            }
        }
    }
</script>
<style lang="css">
    #menu-bar.Inbox{
        width: var(--inboxWidth);
        max-width: 800px;
        min-width: 520px;
        z-index: 1000000;
        align-items:flex-start;
    }
    .Inbox-mf{
        margin-left:6px;
    }

    .inbox .v-navigation-drawer__content{
        overflow-y: hidden !important;
    }
</style>